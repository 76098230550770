var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.manageCategories)?_c('v-row',{staticClass:"wrapper-switch-content-type"},[_c('v-col',{attrs:{"cols":"12","md":"12","justify":"center"}},[_c('v-switch',{staticClass:"switch-content-type",attrs:{"inset":""},model:{value:(_vm.contentType),callback:function ($$v) {_vm.contentType=$$v},expression:"contentType"}}),_c('span',{staticClass:"text-overline"},[_vm._v("Personal / Professional")])],1)],1):_vm._e(),_c('v-row',{staticClass:"wrapper-catg-shrink",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-subtitle-1",attrs:{"cols":"12"}},[(!_vm.manageCategories)?_c('v-menu',{attrs:{"bottom":"","offset-y":"","close-on-click":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card-text',[_c('v-row',[_vm._l((_vm.selection),function(selectionItem,i){return _c('v-col',{key:(selectionItem + "-" + i),staticClass:"shrink"},[_c('v-chip',{attrs:{"close":""},on:{"click:close":function($event){return _vm.selection.splice(i, 1)}}},[_vm._v(" "+_vm._s(selectionItem.name)+" ")])],1)}),(!_vm.selection.length)?_c('v-col',[_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 btn-action-1",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" Categories ")])],1):_vm._e()],2),_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 btn-action",attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mx-auto",class:{'alternate-icon-small': !_vm.mdiMenuDown},attrs:{"right":"","dark":""},on:{"click":function($event){_vm.overlay = true;}}},[_vm._v(" "+_vm._s(_vm.mdiMenuDown)+" ")])],1)],1)]}}],null,false,1467211974)},[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$store.state.customCategories),expression:"!$store.state.customCategories"}],staticClass:"row-categories-check dropdown"},[_c('v-card-title',[_c('span',{staticClass:"title-overline overline"},[_vm._v("Categories")]),_c('a',{staticClass:"lnk-mg-catg",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$store.commit('showCustomCategories', true);}}},[_vm._v("Manage Categories")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.message.status === null)?_c('v-alert',{attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.message.text)+" ")]):_vm._e(),(_vm.message.status === true)?_c('v-alert',{attrs:{"type":"warning"}},[_vm._v(" "+_vm._s(_vm.message.text)+" ")]):_vm._e(),_c('v-treeview',{attrs:{"items":_vm.categories,"selection-type":'independent',"selectable":"","return-object":""},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),(item.status)?_c('span',{staticClass:"custom-loader"},[_c('v-icon',{attrs:{"light":""}},[_vm._v(_vm._s(_vm.mdiCached))])],1):_vm._e()]}}],null,false,1558406526),model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}})],1)],1)],1)],1):_vm._e(),(_vm.manageCategories)?_c('v-card',{staticClass:"row-categories-check"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.categories.length < 7)?_c('v-btn',{staticClass:"btn-main-category",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.addChild(null);}}},[_c('v-icon',{staticClass:"mx-auto",class:{'alternate-icon-small': !_vm.mdiTextBoxPlusOutline},attrs:{"right":"","dark":""}},[_vm._v(_vm._s(_vm.mdiTextBoxPlusOutline))])],1):_vm._e(),(_vm.message.status === null)?_c('v-alert',{attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.message.text)+" ")]):_vm._e(),(_vm.message.status === true)?_c('v-alert',{attrs:{"type":"warning"}},[_vm._v(" "+_vm._s(_vm.message.text)+" ")]):_vm._e(),_c('v-treeview',{attrs:{"items":_vm.categories,"return-object":""},scopedSlots:_vm._u([{key:"append",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":"","disabled":item.status,"title":"Edit"},on:{"click":function($event){return _vm.editChild(item);}}},[_c('v-icon',{staticClass:"mx-auto",class:{'alternate-icon-small': !_vm.mdiPlaylistEdit},attrs:{"right":"","dark":""}},[_vm._v(" "+_vm._s(_vm.mdiPlaylistEdit)+" ")])],1),(item.level < 3 && (item.hasOwnProperty('children') ? item.children.length < 7 : true))?_c('v-btn',{attrs:{"small":"","text":"","disabled":item.status,"title":"Add"},on:{"click":function($event){return _vm.addChild(item);}}},[_c('v-icon',{staticClass:"mx-auto",class:{'alternate-icon-small': !_vm.mdiTextBoxPlusOutline},attrs:{"right":"","dark":""}},[_vm._v(" "+_vm._s(_vm.mdiTextBoxPlusOutline)+" ")])],1):_vm._e(),_c('v-btn',{attrs:{"text":"","small":"","disabled":item.status || item.hasOwnProperty('children'),"title":"Delete"},on:{"click":function($event){return _vm.removeChild(item);}}},[_c('v-icon',{staticClass:"mx-auto",class:{'alternate-icon-small': !_vm.mdiTrashCan},attrs:{"right":"","dark":""}},[_vm._v(" "+_vm._s(_vm.mdiTrashCan)+" ")])],1),_c('v-btn',{attrs:{"text":"","small":"","title":("Privacy: " + (item.public ? 'Public' : 'Private'))},on:{"click":function($event){item.public = !item.public;_vm.saveCategory($event, item, false);}}},[_c('v-icon',{staticClass:"mx-auto",class:{'alternate-icon-small': item.public ? !_vm.mdiEye : !_vm.mdiEyeOff },attrs:{"right":"","dark":""}},[_vm._v(" "+_vm._s(item.public ? _vm.mdiEye : _vm.mdiEyeOff)+" ")])],1),_c('v-btn',{staticClass:"btn-block",attrs:{"text":"","small":"","title":("" + (item.active ? 'Actived in the Menu' : 'Disabled in the Menu'))},on:{"click":function($event){item.active = !item.active;_vm.saveCategory($event, item, false);}}},[_c('v-icon',{staticClass:"mx-auto",class:{'alternate-icon-small': item.active ? !_vm.mdiLock : !_vm.mdiLockOpenVariant },attrs:{"right":"","dark":""}},[_vm._v(" "+_vm._s(!item.active ? _vm.mdiLock : _vm.mdiLockOpenVariant)+" ")])],1)]}},{key:"label",fn:function(ref){
var item = ref.item;
return [(item.editable)?_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":""},on:{"keyup":function($event){return _vm.saveCategory($event, item, true)}},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}):_vm._e(),(!item.editable)?_c('span',{staticClass:"label-category",on:{"click":function($event){return _vm.editChild(item);}}},[_vm._v(_vm._s(item.name))]):_vm._e(),(item.status)?_c('span',{staticClass:"custom-loader"},[_c('v-icon',{attrs:{"light":""}},[_vm._v(_vm._s(_vm.mdiCached))])],1):_vm._e()]}}],null,false,3695897034),model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}})],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }