<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="1200px"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <v-card>
        <v-card-title>
          <v-col cols="5" md="5">
            <span class="text-h5">Edit</span>
          </v-col>
          <v-col cols="7" md="7" justify="center">
            <v-switch @click="switchProfile()" v-model="contentType" inset class="switch-content-type"></v-switch>
            <span class="text-overline">Personal / Professional</span>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-stepper v-model="step" class="custom-header transparent" alt-labels>
              <v-stepper-header :elevation="6">
                <template>
                    <v-stepper-step
                        :complete="step > 1"
                        :step="1"
                        editable
                        :rules="[() => step1]">
                      Description
                    </v-stepper-step>
                    <v-divider
                    ></v-divider>
                    <v-stepper-step
                        :complete="step > 2"
                        :step="2"
                        :editable="checkStep()"
                        :rules="[() => step2]"
                    >
                      Location
                    </v-stepper-step>
                </template>
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content
                  :step="1"
                  >
                  <v-card
                      color="grey lighten-1 "
                      height="500px"
                  >
                    <div class="container">
                      <v-form class="multi-col-validation" style="width: 100%;margin: 0 auto">
                        <v-row class="d-flex justify-center">
                          <v-col cols="7" md="7" class="position-relative">
                            <div class="wrapper-slider-book-view">
                              <v-switch v-model="modals.file.view_type" inset class="switch-content-type"></v-switch>
                              <span class="text-overline">Slider View / Book View</span>
                            </div>
                            <drag-and-drop @filed="fileLoaded" @rotation_position="rotationPosition" :relatedFiles="modals.file.related_files" :rotatePositionProp="modals.file.rotate_position" :editing="editing" />
                          </v-col>
                           <v-col
                            cols="5"
                            md="5">
                            <v-row>
                              <v-col
                                class="d-flex"
                                cols="4"
                              >
                                <v-select
                                  v-model="daySelected"
                                  :items="days"
                                  label="Day"
                                  outlined
                                  dense
                                ></v-select>
                              </v-col>

                              <v-col
                                class="d-flex"
                                cols="4"
                              >
                                <v-select
                                  v-model="monthSelected"
                                  :items="months"
                                  label="Month"
                                  outlined
                                  dense
                                  @change="changeMonth()"
                                ></v-select>
                              </v-col>

                              <v-col
                                class="d-flex"
                                cols="4"
                              >
                                <v-select
                                  v-model="yearSelected"
                                  :items="years"
                                  label="Year"
                                  outlined
                                  dense
                                  @change="changeYear()"
                                ></v-select>

                                <v-tooltip top color="#000000" max-width="200px">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      :class="{'alternate-icon-small': !mdiInformationOutline}"
                                      class="mx-auto icon-info-date"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      {{ mdiInformationOutline }}
                                    </v-icon>
                                  </template>
                                  <span>If you only select the year. The day and month will be set to January 1st.</span>
                                </v-tooltip>
                              </v-col>
                            </v-row>

                            <CustomCategories :manageCategories='false' :content_type="content_type" :categories="categories" :categoriesArray="modals.file.categories.indexOf(', ') !== -1 ? modals.file.categories.split(', ') : [modals.file.categories]" @getCategories="getCatgContentType" @setCategories="setCategories" />

                            <v-text-field
                              v-model="modals.file.name"
                              outlined
                              dense
                              label="Title"
                              :error="titleCheck"
                            ></v-text-field>
                            <wysiwyg v-model="modals.file.alternative_text" :error="descriptionCheck" />

                            <div class="tag-input">
                              <div v-for="(tag, index) in tags" :key="tag" class="tag-input__tag">
                                <span @click='removeTag(index)'>x</span>
                                {{ tag }}
                              </div>
                              <div class="wrapper-input-icon">
                                  <v-text-field
                                    label="Enter a tag"
                                    outlined
                                    dense
                                    placeholder="Enter a tag"
                                    hide-details
                                    @keydown.enter='addTag'
                                    @keydown.188='addTag'
                                    @keydown.delete='removeLastTag'
                                    class="txt-enter-tag"
                                  ></v-text-field>
                                <v-tooltip top color="#000000" max-width="200px">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      dark
                                      :class="{'alternate-icon-small': !mdiInformationOutline}"
                                      class="mx-auto"
                                      title="What's a tag?"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      {{ mdiInformationOutline }}
                                    </v-icon>
                                  </template>
                                  <span>Tags are used to put additional information to the Main Category selected above and give more details about your image or content. Choose a small number of categories and create your personal tags to fill your content.</span>
                                </v-tooltip>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-form>
                    </div>
                  </v-card>
                </v-stepper-content>

                <v-stepper-content
                  :step="2"
                  >
                  <v-card
                      color="grey lighten-1"
                  >
                    <v-container style="height: 400px;" v-if="resultUpload">
                      <v-row
                        class="fill-height"
                        align-content="center"
                        justify="center"
                      >
                        <v-col
                          class="text-subtitle-1 text-center"
                          cols="12"
                        >
                          The content has been updated.
                        </v-col>
                    </v-row>
                    </v-container>
                    <v-container style="height: 400px;" v-if="loading && !resultUpload">
                      <v-row
                        class="fill-height"
                        align-content="center"
                        justify="center"
                      >
                        <v-col
                          class="text-subtitle-1 text-center"
                          cols="12"
                        >
                          Updating your content...
                        </v-col>
                        <v-col cols="6">
                          <v-progress-linear
                            color="primary accent-4"
                            indeterminate
                            rounded
                            height="6"
                          ></v-progress-linear>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-container v-if="!loading && !resultUpload">
                      <Map :coords="currentLocation" @updateCoordinates="setCoordinates" v-if="step === 2"></Map>
                    </v-container>
                  </v-card>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="!loading && !resultUpload" text small @click="modals.file.public = !modals.file.public" class="btn-privacy" :title="`Privacy: ${ modals.file.public ? 'Public' : 'Private' }`">
            <v-icon
              dark
              :class="{'alternate-icon-small': modals.file.public ? !mdiEye : !mdiEyeOff }"
              class="mx-auto"
            >
              {{ modals.file.public ? mdiEye : mdiEyeOff }}
            </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="step !== 2"
              color="primary"
              @click="nextStep()"
          >
              Continue
          </v-btn>
          <v-btn v-if="step === 2 && !resultUpload && !loading"
              color="success"
              @click="editFile()">Update</v-btn>
          <v-btn v-if="!loading" @click="modalClose(false)">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import axios from "axios";
  import DragAndDrop from '@/components/DragAndDrop.vue';
  import { mdiInformationOutline, mdiCloudUpload, mdiEye, mdiEyeOff } from '@mdi/js';
  import CustomCategories from '@/components/CustomCategoriesTreeView.vue';
  import Map from '@/components/Map_v2.vue';

  export default {
    props: ['categories', 'categoriesArray', 'modals', 'tagsFull', 'content_type'],
    data () {
      return {
        current_date: this.modals.file.updated_at.split('T')[0].split('-'),
        dayActive: false,
        monthActive: false,
        days: [],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June',	'July',	'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
        years: [],
        daySelected: null,
        monthSelected: null,
        yearSelected: null,
        location: '',
        selected: {},
        service: null,

        dialog: true,
        /* ICONS */
        mdiInformationOutline,
        mdiCloudUpload,
        mdiEye,
        mdiEyeOff,
        /* CALENDAR */
        menu1: false,
        menu2: false,
        /* -------- */
        /* STEPPERS */
        step1: true,
        step2: true,
        step3: true,
        step: 1,
        steps: 2,
        /* -------- */
        currentLocation : { lat : 0, lng : 0},
        /* -------- */
        /* FORMs */
        titleCheck: false,
        descriptionCheck: false,
        categoryCheck: false,
        tags: [],
        endpoint: process.env.VUE_APP_ROOT_API,
        fileUploaded: [],
        file: {
          url: '',
        },
        files: [],
        categorySelected: [],
        cmdCategory: [],
        loading: false,
        resultUpload: false,
        editing: true,
        contentType: false,
        uploading: false,
        arrayFiles: [],
        rotation_position: []
      }
    },
    components: {
      DragAndDrop,
      CustomCategories,
      Map
    },
    computed: {
      zoom() {
        return this.modals.zoom;
      },
    },
    watch: {
      contentType() {
        this.$emit('getCategories', this.contentType ? 1 : 0);
      },
      steps (val) {
        if (this.step > val) {
          this.step = val
        }
      },
      fileUploaded(val) {
        if(val) {
          this.step1 = true;
        }
      }
    },
    mounted() {
      const self = this;
      this.currentLocation = JSON.parse(this.modals.file.location);
      this.contentType = this.content_type ? true : false;
      this.monthSelected = this.months[parseInt(this.current_date[1], 10) - 1];
      this.generateArrayOfYears();

      if(this.tagsFull) {
        const tags_id = this.modals.file.tags_id ? this.modals.file.tags_id.split(', ') : '';
        this.tagsFull.forEach(elm => {
          if(tags_id.includes(String(elm.id))) {
            self.tags.push(elm.name);
          }
        });
      }
    },
    methods: {
      getCatgContentType(val) {
        this.$emit('getCategories', val);
      },
      changeMonth() {
        this.getDaysInMonthUTC(1, parseInt(this.months.indexOf(this.monthSelected), 10) + 1, this.yearSelected);
      },
      changeYear() {
        this.monthSelected = this.months[0];
        this.getDaysInMonthUTC(1, 0, this.yearSelected);
      },
      getDaysInMonthUTC(day, month, year) {
        this.days = [];
        var max = new Date(year, month, 0).getDate();
        for (var i = 1; i <= max; i++) {
          this.days.push(i);
        };
        this.daySelected = day;
      },
      generateArrayOfYears() {
        const max = new Date().getFullYear();
        const min = max - 110;
        this.years = [];

        for (var i = max; i >= min; i--) {
          this.years.push(i);
        }
        this.yearSelected = parseInt(this.current_date[0], 10);
        this.getDaysInMonthUTC(parseInt(this.current_date[2], 10), parseInt(this.current_date[1], 10), this.yearSelected);
      },
      switchProfile() {
        this.categorySelected = [];
        this.cmdCategory = [];
      },
      setCoordinates(position) {
        this.currentLocation = {
          lat: position.lat,
          lng: position.lng
        };
      },
      checkStep() {
        return this.step1 && this.fileUploaded ? true : false;
      },
      modalClose() {
        this.$emit('closingModal', true);
      },
      nextStep () {
        if((this.step === 1) && (!this.modals.file.name.length || !this.modals.file.alternative_text.length || !this.categorySelected.length)) {
          this.titleCheck = !this.modals.file.name.length ? true : false;
          this.descriptionCheck = !this.modals.file.alternative_text.length ? true : false;
          this.categoryCheck = !this.categorySelected.length ? true : false;
          this.step1 = false;
        } else {
          this.step1 = true;
          this.step2 = true;
          this.step3 = true;
          this.step = this.step + 1
        }
      },
      setCategories(selected) {
        const self = this;
        self.categorySelected = [];
        selected.forEach(value  => {
          if (value.parent_id) {
            if (!self.categorySelected.includes(value.parent_id)) {
              self.categorySelected.push(value.parent_id);
            }
          }
          self.categorySelected.push(value.id);
        });
      },
      formatDate(date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },
      addTag(event) {
        event.preventDefault()
        var val = event.target.value.trim()
        if (val.length > 0) {
          this.tags.push(val)
          event.target.value = ''
        }
      },
      removeTag(index) {
        this.tags.splice(index, 1)
      },
      removeLastTag(event) {
        if (event.target.value.length === 0) {
          this.removeTag(this.tags.length - 1)
        }
      },
      editFile() {
        this.loading = true;
        axios.post(`${this.endpoint}/updateFileInfos`, {
          name: this.modals.file.name,
          alternative_text: this.modals.file.alternative_text,
          category: this.categorySelected,
          tags: this.tags,
          location: this.currentLocation,
          date: `${this.yearSelected}-${String(parseInt(this.months.indexOf(this.monthSelected), 10) + 1).padStart(2, '0')}-${String(this.daySelected).padStart(2, '0')}`,
          content_type: this.contentType,
          file_id: this.modals.file.id,
          public: this.modals.file.public,
          view_type: this.modals.file.view_type
        }, {
          headers : {
            'Authorization': this.$store.state.jwtToken
          }
        }).then(responseDB => {
          this.uploadNewFiles();
        });
      },
      fileLoaded(value) {
        this.fileUploaded = value;
      },
      rotationPosition(value) {
        this.rotation_position = value;
      },
      linkFiles() {
        axios.post(`${this.endpoint}/linkFiles`, {
          files: this.arrayFiles,
          date: `${this.yearSelected}-${String(parseInt(this.months.indexOf(this.monthSelected), 10) + 1).padStart(2, '0')}-${String(this.daySelected).padStart(2, '0')}`,
          file_id: this.modals.file.id,
          rotation_position: this.rotation_position
        }, {
          headers : {
            'Authorization': this.$store.state.jwtToken
          }
        }).then(responseDB => {
            this.loading = false;
            this.resultUpload = true;
          this.fileUploaded = [];
        });
      },
      formatString(str) {
        return str.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
      },
      uploadNewFiles() {
        const self = this;
        self.uploading = true;
        self.resultUpload = false;
        self.fileUploaded.forEach((elm) => {
          if (!(typeof elm === 'string')) {
            axios.post(`https://content.selfbiography.com/api/upload`, {
              buffer: elm.file,
              originalname: self.formatString(self.modals.file.name.toLowerCase().trim().replace(/\s+/g, '-')),
              mime: elm.mimeType
            }, {
              headers : {
                'Authorization': this.$store.state.jwtToken
              }}).then(response => {
              this.arrayFiles.push(response.data.Data.Key);
              if(this.arrayFiles.length === this.fileUploaded.length) {
                this.linkFiles();
              }
            })
            .catch(() => {
              console.log('error Upload');
              // ERROR
            });
          } else {
            this.arrayFiles.push(`${elm.split('.webp')[0].replace('https://self-biography-system.s3.us-east-2.amazonaws.com/', '')}.webp`);
            if(this.arrayFiles.length === this.fileUploaded.length) {
              self.linkFiles();
            }
          }
        });

      },
    },
  }
</script>

<style scoped lang="scss">
.v-input__control > .v-input__slot {
  min-height: 41px;
}

.v-select.v-input--dense .v-chip {
  margin-top: 4px;
}

.v-text-field.v-input--dense {
  padding-top: 0;
  display: block;
  margin-bottom: 10px !important;
}

.tag-input {
  width: 100%;
  border: 1px solid #eee;
  font-size: 0.9em;
  box-sizing: border-box;
  padding: 0 10px;
  font-size: 1rem;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-bottom: 16px;
  font-size: 14px;
  padding-top: 10px;
  min-height: 124px;

  &__tag {
    height: 30px;
    float: left;
    margin-right: 10px;
    background-color: #eee;
    margin-bottom: 10px;
    line-height: 27px;
    padding: 0 8px;
    border-radius: 5px;
  }

  &__tag > span {
    cursor: pointer;
    color:#8898aa;
    opacity: 0.75;
  }

  &__text {
    color:#40474e;
    border: none;
    outline: none;
    font-size: 0.9em;
    line-height: 50px;
    background: none;
    width: 100%;
  }
}

.wrapper-input-icon {
  position: relative;
  width: 100%;
  clear: both;
  .v-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2%;
  }
  .v-icon__svg {
    fill: gray;
  }
}

.img-edit {
  height: auto;
  border-radius: 12px;
  max-height: 92%;
  width: auto;
  margin: 0 auto;
  display: block;
  max-width: 100%;
}
.v-input--selection-controls {
  margin-top: 0;
}
.places-dropdown {
  position: absolute;
  top: 55px;
  z-index: 1;
  background: #fff;
  width: 100%;
  max-width: 428px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 40%);
  list-style: none;
  max-height: 354px;
  overflow-y: auto;

  li {
    padding: 8px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    &:last-child {
      border: 0;
    }
  }
}
.switch-content-type {
  width: 40px;
  height: 30px;
  margin-left: 10.5%;
}

.icon-info-date {
  position: relative;
  top: -5px;
  left: 5px;
}

.v-stepper__header {
  margin-bottom: 20px;
}

.v-stepper__content {
  .v-stepper__wrapper {
    overflow: unset;
    .v-card {
      box-shadow: none !important;
    }
  }
}

.v-stepper__items {
  overflow: unset !important;

  .col-5 {
    position: relative;
    top: -55px;
  }
}

.btn-privacy {
  position: relative;
  left: 10px;
}

.wrapper-slider-book-view {
  position: absolute;
  top: -63px;
  left: 50%;
  transform: translateX(-50%);

  .switch-content-type {
    margin-left: 42%;
  }
}
</style>
