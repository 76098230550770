<template>
  <div>
    <v-row class="wrapper-switch-content-type" v-if="manageCategories">
      <v-col cols="12" md="12" justify="center">
        <v-switch v-model="contentType" inset class="switch-content-type"></v-switch>
        <span class="text-overline">Personal / Professional</span>
      </v-col>
    </v-row>
    <v-row justify="center" class="wrapper-catg-shrink">
      <v-col class="text-subtitle-1" cols="12">
        <!-- <v-overlay
          :z-index="2"
          :value="overlay"
          @click="overlay = false;"
        >
        </v-overlay> -->
        <v-menu v-if="!manageCategories"
          bottom
          offset-y
          :close-on-click="true"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-card-text>
              <v-row>
                <v-col v-for="(selectionItem, i) in selection" :key="`${selectionItem}-${i}`" class="shrink">
                  <v-chip
                    close @click:close="selection.splice(i, 1)">
                    {{ selectionItem.name }}
                  </v-chip>
                </v-col>
                <v-col v-if="!selection.length">
                  <v-btn class="ma-2 btn-action-1" text v-bind="attrs" v-on="on">
                    Categories
                  </v-btn>
                </v-col>
              </v-row>
              <v-btn class="ma-2 btn-action" text v-bind="attrs" v-on="on">
                <v-icon
                  right
                  dark
                  :class="{'alternate-icon-small': !mdiMenuDown}"
                  class="mx-auto"
                  @click="overlay = true;"
                >
                  {{ mdiMenuDown }}
                </v-icon>
              </v-btn>
            </v-card-text>
          </template>
          <v-card class="row-categories-check dropdown" v-show="!$store.state.customCategories">
            <v-card-title>
              <span class="title-overline overline">Categories</span>
              <a href="javascript:;" class="lnk-mg-catg" @click="$store.commit('showCustomCategories', true);">Manage Categories</a>
            </v-card-title>
            <v-row>
              <v-col cols="12">
                <v-alert type="success" v-if="message.status === null">
                  {{ message.text }}
                </v-alert>
                <v-alert type="warning" v-if="message.status === true">
                  {{ message.text }}
                </v-alert>
                <v-treeview
                  v-model="selection"
                  :items="categories"
                  :selection-type="'independent'"
                  selectable
                  return-object
                >
                  <template slot="label" slot-scope="{ item }">
                    {{ item.name }}
                    <span class="custom-loader" v-if="item.status">
                      <v-icon light>{{ mdiCached }}</v-icon>
                    </span>
                  </template>
                </v-treeview>
              </v-col>
            </v-row>
          </v-card>
        </v-menu>

        <v-card class="row-categories-check" v-if="manageCategories">
          <v-row>
            <v-col cols="12">
              <v-btn small text @click="addChild(null);" class="btn-main-category" v-if="categories.length < 7">
                <v-icon
                  right
                  dark
                  :class="{'alternate-icon-small': !mdiTextBoxPlusOutline}"
                  class="mx-auto"
                >{{ mdiTextBoxPlusOutline }}</v-icon>
              </v-btn>
              <v-alert type="success" v-if="message.status === null">
                {{ message.text }}
              </v-alert>
              <v-alert type="warning" v-if="message.status === true">
                {{ message.text }}
              </v-alert>
              <v-treeview
                v-model="selection"
                :items="categories"
                return-object
              >
                <template slot="append" slot-scope="{ item }">
                  <v-btn text small @click="editChild(item);" :disabled="item.status" title="Edit">
                    <v-icon
                      right
                      dark
                      :class="{'alternate-icon-small': !mdiPlaylistEdit}"
                      class="mx-auto"
                    >
                      {{ mdiPlaylistEdit }}
                    </v-icon>
                  </v-btn>
                  <v-btn small text @click="addChild(item);" :disabled="item.status" v-if="item.level < 3 && (item.hasOwnProperty('children') ? item.children.length < 7 : true)" title="Add">
                    <v-icon
                      right
                      dark
                      :class="{'alternate-icon-small': !mdiTextBoxPlusOutline}"
                      class="mx-auto"
                    >
                      {{ mdiTextBoxPlusOutline }}
                    </v-icon>
                  </v-btn>
                  <v-btn text small @click="removeChild(item);" :disabled="item.status || item.hasOwnProperty('children')" title="Delete">
                    <v-icon
                      right
                      dark
                      :class="{'alternate-icon-small': !mdiTrashCan}"
                      class="mx-auto"
                    >
                      {{ mdiTrashCan }}
                    </v-icon>
                  </v-btn>
                  <v-btn text small @click="item.public = !item.public;saveCategory($event, item, false);" :title="`Privacy: ${item.public ? 'Public' : 'Private' }`">
                    <v-icon
                      right
                      dark
                      :class="{'alternate-icon-small': item.public ? !mdiEye : !mdiEyeOff }"
                      class="mx-auto"
                    >
                      {{ item.public ? mdiEye : mdiEyeOff }}
                    </v-icon>
                  </v-btn>
                  <v-btn class="btn-block" text small @click="item.active = !item.active;saveCategory($event, item, false);" :title="`${item.active ? 'Actived in the Menu' : 'Disabled in the Menu' }`">
                    <v-icon
                      right
                      dark
                      :class="{'alternate-icon-small': item.active ? !mdiLock : !mdiLockOpenVariant }"
                      class="mx-auto"
                    >
                      {{ !item.active ? mdiLock : mdiLockOpenVariant }}
                    </v-icon>
                  </v-btn>
                </template>
                <template slot="label" slot-scope="{ item }">
                  <v-text-field
                    v-model="item.name" v-if="item.editable" @keyup="saveCategory($event, item, true)"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                  <span v-if="!item.editable" class="label-category" @click="editChild(item);">{{item.name}}</span>
                  <span class="custom-loader" v-if="item.status">
                    <v-icon light>{{ mdiCached }}</v-icon>
                  </span>
                </template>
              </v-treeview>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import { mdiTextBoxPlusOutline, mdiTrashCan, mdiCached, mdiMenuDown, mdiPlaylistEdit, mdiEyeOff, mdiEye, mdiLock, mdiLockOpenVariant } from '@mdi/js';

export default {
  props: ['categories', 'content_type', 'categoriesArray', 'manageCategories'],
  data () {
    return {
      overlay: false,
      /* Icons */
      mdiTrashCan,
      mdiTextBoxPlusOutline,
      mdiCached,
      mdiMenuDown,
      mdiPlaylistEdit,
      mdiEyeOff,
      mdiEye,
      mdiLock,
      mdiLockOpenVariant,
      /* ---- */
      selection: [],
      message: {
        status: false,
        text: ''
      },
      timer: null,
      endpoint: process.env.VUE_APP_ROOT_API,
      contentType: false,
    }
  },
  watch: {
    contentType() {
      this.$emit('getCategories', this.contentType ? 1 : 0);
    },
    selection() {
      this.$emit('setCategories', this.selection);
    }
  },
  mounted() {
    this.findItem();
  },
  methods: {
    /* START CATEGORIES */
    findItem() {
      this.categoriesArray.forEach((value) => {
        if(parseInt(value, 10) !== 1) {
          const arrayNested = this.findNestedObj(this.categories, 'id', parseInt(value, 10));
          this.selection.push(arrayNested);
        }
      });
    },
    saveCategory(e, item, timeout){
      if(timeout) {
        clearTimeout(this.timer);
      }
      const type = item.type === 2 ? 'update' : item.type;
      this.timer = setTimeout(() => {
        this.$set(item, "status", true);
        this.$set(item, "editable", false);
        e.target.blur();
        axios.post(`${this.endpoint}/insertCategory`, {
          name: item.name,
          id: item.id,
          type: type,
          content_type: this.content_type,
          level: item.level,
          parent_id: item.parent_id,
          public: !item.active ? false : item.public,
          active: item.active
        }, {
        headers : {
          'Authorization': this.$store.state.jwtToken
        }
        }).then(responseDB => {
          this.$set(item, "status", false);
          this.$emit('getCategories', this.content_type);
        });
      }, timeout ? 1500 : 0);
    },
    addChild(item) {
      const searchedArray = this.categories;
      const name = `New Category`;
      let id = Math.random();
      let level = 1;
      let parent_id = 0;
      let itemInput = searchedArray;

      if(item) {
        if (!item.children) {
          this.$set(item, "children", []);
        }
        id = item.id + Math.random();
        level = item.level + 1;
        parent_id = item.id;
        itemInput = item.children;
      }

      itemInput.push({
        id,
        name,
        type: 'insert',
        level: level,
        parent_id: parent_id
      });
    },
    editChild(item) {
      this.$set(item, "editable", true);
    },
    removeChild(item) {
      const searchedArray = this.categories;
      this.$set(item, "status", true);
      if(item.id % 1 === 0) {
        axios.post(`${this.endpoint}/deleteCategory`, {
          category_id: item.id
        }, {
        headers : {
          'Authorization': this.$store.state.jwtToken
        }
        }).then(responseDB => {
          this.$set(item, "status", false);
          if(!responseDB.data.error) {
            if(item.level > 1) {
              const arrayNested = this.findNestedObj(searchedArray, 'id', item.parent_id).children;
              const index = arrayNested.map(e => e.id).indexOf(item.id);
              arrayNested.splice(index, 1);
            } else {
              searchedArray.splice(searchedArray.map(e => e.id).indexOf(item.id), 1);
            }
          }
          this.$emit('getCategories', this.content_type);
          this.$set(this.message, "status", responseDB.data.error);
          this.$set(this.message, "text", responseDB.data.message);
          setTimeout(() => {
            this.$set(this.message, "status", false);
            this.$set(this.message, "text", '');
          }, 5000);
        });
      } else {
        if(item.level > 1) {
          const arrayNested = this.findNestedObj(searchedArray, 'id', item.parent_id).children;
          const index = arrayNested.map(e => e.id).indexOf(item.id);
          arrayNested.splice(index, 1);
        } else {
          searchedArray.splice(searchedArray.map(e => e.id).indexOf(item.id), 1);
        }
      }
    },
    findNestedObj(entireObj, keyToFind, valToFind) {
      let foundObj;
      JSON.stringify(entireObj, (_, nestedValue) => {
        if (nestedValue && nestedValue[keyToFind] === valToFind) {
          foundObj = nestedValue;
        }
        return nestedValue;
      });
      return foundObj;
    },
    /* END CATEGORIES */
  }
}
</script>

<style lang="scss">
.row-categories-check {
  clear: both;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 25px 15px 20px;
  box-shadow: none !important;
  &.dropdown {
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
              0px 6px 10px 0px rgb(0 0 0 / 14%),
              0px 1px 18px 0px rgb(0 0 0 / 12%) !important;
    border: 1px solid #ccc !important;
  }

  .v-treeview {
    .v-btn:not(.v-btn--round).v-size--small {
      min-width: 0;
      padding: 1px 5px !important;
      margin: 0;
    }
  }

  .btn-main-category {
    position: absolute;
    right: 4.5%;
    top: -4px;
  }

  .v-card__title {
    position: relative;
    padding-top: 0;
    margin-bottom: 10px;
    padding-bottom: 5px;
  }

  .title-overline {
    float: left;
  }

  .v-menu__content {
    padding-bottom: 20px;
  }
}

.custom-loader {
  position: absolute;
  right: 30%;
  top: 22%;
  margin: 0;
  animation: loader 0.8s infinite;
  display: flex;
  width: 24px;
  height: 24px;
  transform-origin: 50% 50%;
  opacity: 0.8;
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrapper-catg-shrink {
  border-radius: 6px;
  border: 1px solid #ccc;
  position: relative;
  margin: 5px 0 10px!important;
  .shrink {
    padding: 5px;
  }

  .v-card__text {
    padding: 4px 10px 10px !important;
    width: 96%;
    height: 70px;
    overflow: hidden;
    overflow-y: auto;

    @media(max-width: 940px) {
      height: 45px;

      .col {
        padding: 0;
      }
    }
  }
}

.btn-action-1 {
  width: 100%;
  height: 80px !important;
  padding: 0 !important;
  margin: 0 !important;
  text-align: left !important;
  span {
    display: block;
  }
  text-transform: none;
}
.v-btn:not(.v-btn--round).v-size--default.btn-action {
  position: absolute;
  top: -3px;
  right: 0;
  height: 80px !important;
  padding: 0 !important;
  min-width: 10px !important;

  @media(max-width: 940px) {
    height: 55px !important;
  }
}
.switch-content-type {
  width: 40px;
  height: 30px;
  margin-left: 28%;
}
.wrapper-switch-content-type {
  position: absolute;
  top: 0;
  right: 20px;
  .text-overline {
    font-size: 8px !important;
  }
}
.label-category {
  cursor: pointer;
}

.lnk-mg-catg {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 12px;
}

.btn-block {
  color: #ec4b4b !important;
}
</style>
