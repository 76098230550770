<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="1200px"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <v-card>
        <v-card-title>
          <v-col cols="5" md="5">
            <span class="text-h5">Upload</span>
          </v-col>
          <v-col cols="7" md="7" justify="center">
            <div v-if="step === 1" :class="{'float-right': $vuetify.breakpoint.mdAndDown}">
              <v-switch v-model="modals.view_type" inset class="switch-content-type"></v-switch>
              <span class="text-overline">Slider View / Book View</span>
            </div>

            <div v-if="step !== 1" :class="{'float-right': $vuetify.breakpoint.mdAndDown}">
              <v-switch v-model="contentType" inset class="switch-content-type"></v-switch>
              <span class="text-overline">Personal / Professional</span>
            </div>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-stepper v-model="step" class="custom-header transparent" alt-labels>
              <v-stepper-header :elevation="6">
                <template>
                  <v-stepper-step
                        :complete="step > 1"
                        :step="1"
                        editable
                        :rules="[() => step1]"
                    >
                      Upload a file
                    </v-stepper-step>
                    <v-divider
                    ></v-divider>
                    <v-stepper-step
                        :complete="step > 2"
                        :step="2"
                        :editable="checkStep()"
                        :rules="[() => step2]"
                    >
                      <center>Description</center>
                    </v-stepper-step>
                    <v-divider
                    ></v-divider>
                    <v-stepper-step
                        :complete="step > 3"
                        :step="3"
                        :rules="[() => step3]"
                    >
                      Location
                    </v-stepper-step>
                </template>
              </v-stepper-header>
              <v-stepper-items>
                <v-container style="height: 400px;" v-if="resultUpload">
                  <v-row
                    class="fill-height"
                    align-content="center"
                    justify="center"
                  >
                    <v-col
                      class="text-subtitle-1 text-center"
                      cols="12"
                    >
                      The content has been uploaded.
                    </v-col>
                </v-row>
                </v-container>
                <v-container style="height: 400px;" v-if="loading && !resultUpload">
                  <v-row
                    class="fill-height"
                    align-content="center"
                    justify="center"
                  >
                    <v-col
                      class="text-subtitle-1 text-center"
                      cols="12"
                    >
                      Uploading your files - {{file_progress}}%
                    </v-col>
                    <v-col cols="6">
                      <v-progress-linear
                        color="primary accent-4"
                        indeterminate
                        rounded
                        height="6"
                      ></v-progress-linear>
                    </v-col>
                  </v-row>
                </v-container>
                <v-stepper-content :step="1" v-if="!resultUpload && !loading">
                  <v-card
                      color="grey lighten-1"
                      height="500px"
                  >
                    <div class="container">
                      <div class="row justify-content-center" v-show="!resultUpload" v-bind:class="{ loading: loading }">
                        <div class="col-lg-12 position-relative">
                          <drag-and-drop  @rotation_position="rotationPosition"  @filed="fileLoaded"  />
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-stepper-content>

                <v-stepper-content v-if="!resultUpload && !loading" :step="2">
                  <v-card
                      color="grey lighten-1"
                  >
                    <div class="container step-2-uploader">
                      <v-form class="multi-col-validation" style="width: 100%;margin: 0 auto">
                        <v-row class="d-flex justify-center">
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-row>
                              <v-col
                                class="d-flex"
                                cols="4"
                              >
                                <v-select
                                  v-model="monthSelected"
                                  :items="months"
                                  label="Month"
                                  outlined
                                  dense
                                  @change="changeMonth()"
                                ></v-select>
                              </v-col>

                              <v-col
                                class="d-flex"
                                cols="4"
                              >
                                <v-select
                                  v-model="daySelected"
                                  :items="days"
                                  label="Day"
                                  outlined
                                  dense
                                ></v-select>
                              </v-col>

                              <v-col
                                class="d-flex"
                                cols="4"
                              >
                                <v-select
                                  v-model="yearSelected"
                                  :items="years"
                                  label="Year"
                                  outlined
                                  dense
                                  @change="changeYear()"
                                ></v-select>
                                <v-tooltip top color="#000000" max-width="200px" v-if="!$vuetify.breakpoint.mdAndDown">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      :class="{'alternate-icon-small': !mdiInformationOutline}"
                                      class="mx-auto icon-info-date"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      {{ mdiInformationOutline }}
                                    </v-icon>
                                  </template>
                                  <span>If you only select the year. The day and month will be set to January 1st.</span>
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-text-field
                              placeholder="Type the title related with the image"
                              v-model="modals.title"
                              label="Title"
                              outlined
                              dense
                              hide-details
                              :error="titleCheck"
                            ></v-text-field>
                          </v-col>

                          <v-col
                            cols="12"
                            md="6" style="position: relative;top: -5px;"
                          >
                            <a v-if="!$vuetify.breakpoint.mdAndDown" href="javascript:;" class="lnk-mg-catg" @click="$store.commit('showCustomCategories', true);">Manage Categories</a>
                            <CustomCategories :manageCategories='false' :content_type="content_type" :categories="categories" :categoriesArray="[]" @getCategories="getCatgContentType" @setCategories="setCategories" />
                          </v-col>

                          <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6" class="wysiwyg-editor">
                            <wysiwyg v-model="modals.description" :error="descriptionCheck" />
                          </v-col>

                          <v-col
                            cols="12"
                            md="6"
                            v-if="!$vuetify.breakpoint.mdAndDown"
                          >
                            <div class="tag-input">
                              <div v-for="(tag, index) in tags" :key="tag" class="tag-input__tag">
                                <span @click='removeTag(index)'>x</span>
                                {{ tag }}
                              </div>
                              <div class="wrapper-input-icon">
                                <v-text-field
                                  label="Enter a tag"
                                  outlined
                                  dense
                                  placeholder="Enter a tag"
                                  hide-details
                                  @keydown.enter='addTag'
                                  @keydown.188='addTag'
                                  @keydown.delete='removeLastTag'
                                  class="txt-enter-tag"
                                ></v-text-field>
                                <v-tooltip top color="#000000" max-width="200px">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      :class="{'alternate-icon-small': !mdiInformationOutline}"
                                      class="mx-auto"
                                      title="What's a tag?"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      {{ mdiInformationOutline }}
                                    </v-icon>
                                  </template>
                                  <span>Tags are used to put additional informations to the Main Category selected above and give more details about your image or content. Choose a small number of categories and create your personal tags to fill your content.</span>
                                </v-tooltip>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-form>
                    </div>
                  </v-card>
                </v-stepper-content>

                <v-stepper-content v-if="!resultUpload && !loading" :step="3">
                  <v-card
                      color="grey lighten-1"
                      height="500px"
                  >
                    <v-container v-if="!loading && !resultUpload">
                      <Map :coords="currentLocation" @updateCoordinates="setCoordinates" v-if="step === 3"></Map>
                    </v-container>
                  </v-card>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn text small @click="modals.public = !modals.public" class="btn-privacy" :title="`Privacy: ${ modals.public ? 'Public' : 'Private' }`">
            <v-icon
              dark
              :class="{'alternate-icon-small': modals.public ? !mdiEye : !mdiEyeOff }"
              class="mx-auto"
            >
              {{ modals.public ? mdiEye : mdiEyeOff }}
            </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="step !== 3 && !resultUpload && !loading" color="primary" @click="nextStep()">Continue</v-btn>
          <v-btn v-if="step === 3 && !resultUpload && !loading" color="success" @click="submitFiles(0)">Save</v-btn>
          <v-btn v-if="resultUpload && !loading" color="success" @click="newFile()">New File</v-btn>
          <v-btn v-if="!loading" @click="modalClose()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import axios from "axios";
  import DragAndDrop from '@/components/DragAndDrop.vue';
  import { mdiInformationOutline, mdiCloudUpload, mdiEye, mdiEyeOff } from '@mdi/js';
  import CustomCategories from '@/components/CustomCategoriesTreeView.vue';
  import Map from '@/components/Map_v2.vue';

  export default {
    props: ['categories', 'categoriesArray', 'content_type'],
    data () {
      return {
        current_date: (new Date().toLocaleDateString('en-CA').slice(0, 19).replace('T', ' ').split(' ')[0].split('-')),
        dayActive: false,
        monthActive: false,
        days: [],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June',	'July',	'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
        years: [],
        daySelected: null,
        monthSelected: null,
        yearSelected: null,
        /* ICONS */
        mdiInformationOutline,
        mdiCloudUpload,
        mdiEye,
        mdiEyeOff,
        /* -- */
        location: '',
        places: [],
        selected: {},
        service: null,

        user: {
          id: 0,
        },
        /* CALENDAR */
        menu1: false,
        menu2: false,
        /* -------- */
        /* STEPPERS */
        step1: true,
        step2: true,
        step3: true,
        step: 1,
        steps: 3,
        dialog: true,
        fileStatus: true,
        /* -------- */
        /* MAPS */
        currentLocation : { lat : 0, lng : 0},
        /* -------- */
        /* FORMs */
        titleCheck: false,
        descriptionCheck: false,
        categoryCheck: false,
        tags: [],
        modals: {
          content: false,
          edit: false,
          upload: false,
          zoom: false,
          title: '',
          description: '',
          view_type: 0,
          file: {
            created_at: '',
            url: '',
            name: '',
          },
        },
        /* -- */
        endpoint: process.env.VUE_APP_ROOT_API,
        fileUploaded: [],
        userCategories: [],
        file: {
          url: '',
        },
        files: [0],
        deletingFile: false,
        categorySelected: [],
        cmdCategory: [],
        loading: false,
        uploadPercentage: 0,
        resultUpload: false,
        networks: [
          { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f share-icon', color: '#1877f2' },
        ],
        fbConverting: false,
        fileId: null,
        arrayFiles : [],
        contentType: false,
        rotation_position: [],
        file_progress: 0
      }
    },
    components: {
      DragAndDrop,
      CustomCategories,
      Map
    },
    computed: {
      zoom() {
        return this.modals.zoom;
      },
    },
    watch: {
      contentType() {
        this.cmdCategory = [];
        this.$emit('getCategories', this.contentType ? 1 : 0);
      },
      switchLocation(val) {
        if (val) {
          this.geolocation();
        }
      },
      steps (val) {
        if (this.step > val) {
          this.step = val
        }
      },
      fileUploaded(val) {
        if(val) {
          this.step1 = true;
        }
      },
    },
    mounted() {
      const self = this;
      self.contentType = this.content_type ? true : false;
      this.monthSelected = this.months[parseInt(this.current_date[1], 10) - 1];
      this.generateArrayOfYears();
    },
    methods: {
      rotationPosition(value) {
        this.rotation_position = value;
      },
      getCatgContentType(val) {
        this.$emit('getCategories', val);
      },
      changeMonth() {
        this.getDaysInMonthUTC(1, parseInt(this.months.indexOf(this.monthSelected), 10) + 1, this.yearSelected);
      },
      changeYear() {
        this.monthSelected = this.months[0];
        this.getDaysInMonthUTC(1, 0, this.yearSelected);
      },
      getDaysInMonthUTC(day, month, year) {
        this.days = [];
        var max = new Date(year, month, 0).getDate();
        for (var i = 1; i <= max; i++) {
          this.days.push(i);
        };
        this.daySelected = day;
      },
      generateArrayOfYears() {
        const max = new Date().getFullYear();
        const min = max - 110;
        this.years = [];

        for (var i = max; i >= min; i--) {
          this.years.push(i);
        }
        this.yearSelected = parseInt(this.current_date[0], 10);
        this.getDaysInMonthUTC(parseInt(this.current_date[2], 10), parseInt(this.current_date[1], 10), this.yearSelected);
      },
      checkStep() {
        return this.step1 && this.fileUploaded.length ? true : false;
      },
      modalClose(status) {
        this.$emit('closingModal', true);
      },
      nextStep () {
        if (this.step === this.steps) {
          this.step = 1
        } else {
          if((this.step === 1) && !(this.fileUploaded.length)) {
            this.step1 = false;
          } else if((this.step === 2) && (!this.modals.title.length || !this.modals.description.length || !this.categorySelected.length)) {
            this.titleCheck = !this.modals.title.length ? true : false;
            this.descriptionCheck =  !this.modals.description.length ? true : false;
            this.categoryCheck = !this.categorySelected.length ? true : false;
            this.step2 = false;
          } else {
            this.step1 = true;
            this.step2 = true;
            this.step3 = true;
            this.step = this.step + 1;
          }
        }
      },
      setCategories(selected) {
        const self = this;
        self.categorySelected = [];
        selected.forEach(value  => {
          if (value.parent_id) {
            if (!self.categorySelected.includes(value.parent_id)) {
              self.categorySelected.push(value.parent_id);
            }
          }
          self.categorySelected.push(value.id);
        });
      },
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      fileLoaded(value) {
        this.fileUploaded = value;
      },
      setCoordinates(position) {
        this.currentLocation = {
          lat: position.lat,
          lng: position.lng
        };
      },
      addTag(event) {
        event.preventDefault()
        var val = event.target.value.trim()
        if (val.length > 0) {
          this.tags.push(val)
          event.target.value = ''
        }
      },
      removeTag(index) {
        this.tags.splice(index, 1)
      },
      removeLastTag(event) {
        if (event.target.value.length === 0) {
          this.removeTag(this.tags.length - 1)
        }
      },
      newFile() {
        this.$emit('newFile');
      },
      formatString(str) {
        return str.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
      },
      linkFiles() {
        axios.post(`${this.endpoint}/saveFileInfos`, {
          name: this.modals.title,
          alternative_text: this.modals.description,
          category: this.categorySelected,
          tags: this.tags,
          location: this.currentLocation,
          date: `${this.yearSelected}-${String(parseInt(this.months.indexOf(this.monthSelected), 10) + 1).padStart(2, '0')}-${String(this.daySelected).padStart(2, '0')}`,
          contentType: this.contentType,
          public: this.modals.public,
          view_type: this.modals.view_type,
        }, {
          headers : {
            'Authorization': this.$store.state.jwtToken
          }
        }).then(responseDB => {
          axios.post(`${this.endpoint}/linkFiles`, {
            files: this.arrayFiles,
            date: `${this.yearSelected}-${String(parseInt(this.months.indexOf(this.monthSelected), 10) + 1).padStart(2, '0')}-${String(this.daySelected).padStart(2, '0')}`,
            file_id: responseDB.data.fileInfos,
            rotation_position: this.rotation_position
          }, {
            headers : {
              'Authorization': this.$store.state.jwtToken
            }
          }).then(responseDB => {
            this.loading = false;
            this.resultUpload = true;
            this.fileUploaded = [];
          });
          if(this.fileUploaded.length === 1) {
            this.loading = false;
            this.resultUpload = true;
          }
        });
      },
      submitFiles() {
        this.loading = true;
        // const formData = new FormData();

        this.fileUploaded.forEach((elm) => {
          if (typeof elm === 'string') {
            if(elm.indexOf('amazonaws.com/') !== -1) {
              this.arrayFiles.push(elm.split('amazonaws.com/')[1].split('?X-Amz-Algorithm')[0]);
            } else {
              this.arrayFiles.push(elm);
            }
            if(this.arrayFiles.length === this.fileUploaded.length) {
              this.linkFiles();
            }
          } else {
            axios.post(`https://content.selfbiography.com/api/upload`, {
              buffer: elm.file,
              originalname: this.formatString(this.modals.title.toLowerCase().trim().replace(/\s+/g, '-')),
              mime: elm.mimeType
            }, {
              headers : {
                'Authorization': this.$store.state.jwtToken
              },
              onUploadProgress: (event) => {
                this.file_progress = Math.round(
                  (event.loaded * 100) / event.total
                );
              },
            }).then(response => {
              this.arrayFiles.push(response.data.Data.Key);
              if(this.arrayFiles.length === this.fileUploaded.length) {
                this.linkFiles();
              }
            })
            .catch((error) => {
              console.log('error Upload: ', error);
            });
          }
        });
      },
    },
  }
</script>

<style scoped lang="scss">
.v-input__control > .v-input__slot {
  min-height: 41px;
}

.v-select.v-input--dense .v-chip {
  margin-top: 4px;
}

.v-text-field.v-input--dense {
  padding-top: 0;
  display: block;
  margin-bottom: 10px !important;
}

.tag-input {
  width: 100%;
  border: 1px solid #eee;
  font-size: 0.9em;
  box-sizing: border-box;
  padding: 0 10px;
  font-size: 1rem;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-bottom: 16px;
  font-size: 14px;
  padding-top: 10px;
  min-height: 124px;

  &__tag {
    height: 30px;
    float: left;
    margin-right: 10px;
    background-color: #eee;
    margin-bottom: 10px;
    line-height: 27px;
    padding: 0 8px;
    border-radius: 5px;
  }

  &__tag > span {
    cursor: pointer;
    color:#8898aa;
    opacity: 0.75;
  }

  &__text {
    color:#40474e;
    border: none;
    outline: none;
    font-size: 0.9em;
    line-height: 50px;
    background: none;
    width: 100%;
  }
}

.wrapper-input-icon {
  position: relative;
  width: 100%;
  clear: both;
  .v-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2%;
  }
  .v-icon__svg {
    fill: gray;
  }
}
.v-input--selection-controls {
  margin-top: 0;
}

.switch-content-type {
  width: 40px;
  height: 30px;
  margin-left: 10.5%;
}

.icon-info-date {
  position: relative;
  top: -5px;
  left: 5px;
}
.lnk-mg-catg {
  position: absolute;
  top: -15px;
  right: 15px
}
.wrapper-slider-book-view {
  position: absolute;
  top: -63px;
  left: 50%;
  transform: translateX(-50%);

  .switch-content-type {
    margin-left: 42%;
  }
}

@media(max-width: 940px) {
  .v-stepper__content,
  .v-card__text,
  .v-card__title {
    padding: 0 !important;
  }
  .switch-content-type {
    margin: 0 auto;
  }

  .v-application .text-overline {
    font-size: 0.55rem !important;
  }

  .step-2-uploader {
    .col-12 {
      padding: 3px 2px 0;
    }
  }
}

.wysiwyg-editor {
  position: relative;
  top: -60px;
  @media(max-width: 940px) {
    top: 0;
  }
}
</style>
