<template>
  <v-row justify="center">
    <v-dialog
      max-width="100vw"
      v-model="dialog"
      fullscreen
    >
      <div class="modal-zoom">
        <v-card>
          <v-card-text>
            <div class="flag-container" v-if="parseInt(modals.file.categories, 10) === 1">
              <div class="flag">Draft</div>
            </div>
            <div v-if="!modals.file.view_type">
              <!-- cycle -->
              <v-carousel v-if="modals.file.related_files.length > 1" height="100%">
                <v-carousel-item
                  v-for="(item,i) in modals.file.related_files"
                  :key="i"
                >
                  <img v-if="isImage(item)" :src="`${item}`" :class="`rotate_images__position_${modals.file.rotate_position[i]}`" />
                  <video width="100%" height="100%" controls v-else>
                    <source :src="item" type="video/mp4">
                    Your browser does not support the video tag.
                  </video>
                </v-carousel-item>
              </v-carousel>
              <div v-else>
                <img v-if="isImage(modals.file.related_files[0])" :src="`${modals.file.related_files[0]}`" :class="`rotate_images__position_${modals.file.rotate_position[0]}`" />
                <video width="100%" height="100%" controls v-else>
                  <source :src="modals.file.related_files[0]" type="video/mp4">
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
            <iframe src="/books/comic-book-v2/?v=8" v-else id="inner_iframe" class="iframe-modal"
              title="Comic" frameborder="0" allowtransparency="true"></iframe>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <div class="text-muted text-center mx-auto">
              <small>{{ modals.file.name }} - {{ formatDate(modals.file.created_at) }}</small>
            </div>
            <v-spacer></v-spacer>
            <v-btn
              @click="$emit('closingModal', false)"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    props: ['modals'],
    data () {
      return {
        dialog: true,
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June',	'July',	'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
        endpoint: process.env.VUE_APP_ROOT_API,
      }
    },
    mounted() {
      if(this.modals.file.view_type) {
        this.callIframe();
      }
    },
    methods: {
      isImage(filePath) {
        return filePath && filePath.indexOf('mp4') < 0;
      },
      callIframe() {
        const iframe = document.getElementById("inner_iframe");
        const self = this;
        let iWindow = null;
        iframe.addEventListener("load", () => {
          iWindow = iframe.contentWindow;
          if (iWindow === null) {
            self.callIframe();
          }
          iWindow.postMessage(['book-infos', {related_files: this.modals.file.related_files, rotate_position: this.modals.file.rotate_position}]);
        });
      },
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${this.months[parseInt(month, 10) - 1]} ${day} ${year}`
      },
    },
  }
</script>

<style scoped lang="scss">
  .theme--light.v-card {
    background: #000;
    color: #fff !important
  }
  .v-card__text {
    height: calc(100vh - 75px);
    display: flex;
    justify-content: center;
    overflow-y: hidden;

    @media(max-width: 940px) {
      height: 100vh;
    }

    img {
      height: auto;
      border-radius: 12px;
      width: auto;
      margin: 0 auto;
      display: block;
      max-width: 100%;
      object-fit: contain;
    }

    .iframe-modal {
      width: 75vw;
      height: 88vh;

      // @include below(769px) {
      //   width: 98vw;
      //   height: 85vh;
      // }
    }
  }

  .v-dialog {
    .v-divider + .v-card__actions {
      background: #000 !important;
    }
  }

</style>
