<template>
  <v-row justify="center">
    <v-dialog class="modal-detail"
      persistent
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      max-width="900px"
      v-model="dialog"
    >
      <v-card>
        <div class="d-flex justify-space-between align-center memory-bit-banner">
          <div class="avatar-infos">
            <v-avatar size="50">
              <v-img src="@/assets/images/avatars/1.png"></v-img>
            </v-avatar>
            <span>&nbsp;&nbsp;{{ user.name }}</span>
          </div>

          <span class="text-center mx-auto">
            <!-- {{ getCategoryName(modals.file.categories) }} -->
            <small class="txt-date text-muted text-center mx-auto">Memory Bit / {{ formatDate(modals.file.updated_at.split('T')[0]) }}</small>
          </span>

          <div class="wrapper-avatars" v-if="!$vuetify.breakpoint.mdAndDown">
            <span class="text--primary text-center font-weight-medium">
              18 connections
            </span>
            <v-avatar size="40">
              <v-img src="@/assets/images/avatars/1.png"></v-img>
            </v-avatar>
            <v-avatar size="40">
              <v-img src="@/assets/images/avatars/1.png"></v-img>
            </v-avatar>
            <v-avatar size="40">
              <v-img src="@/assets/images/avatars/1.png"></v-img>
            </v-avatar>
            <v-avatar size="40">
              <v-img src="@/assets/images/avatars/1.png"></v-img>
            </v-avatar>
            <v-avatar size="40">
              <v-img src="@/assets/images/avatars/1.png"></v-img>
            </v-avatar>
          </div>
        </div>
        <v-divider></v-divider>
        <h5 class="text-h5 text-muted text-left mx-auto mt-8 ml-30"  :class="{'modal-details--text-h5': textStatus }">{{ modals.file.name }}</h5>
        <v-card-text gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)">
          <v-container style="height: 400px;" v-if="deletingFile">
            <v-row
              class="fill-height"
              align-content="center"
              justify="center"
            >
              <v-col v-if="!loading"
                class="text-subtitle-1 text-center"
                cols="12"
              >
                {{ result ? 'The content has been deleted.' : 'Are you sure you want to delete this content?' }}
              </v-col>
              <v-col cols="12" class="justify-content-center wrapper-buttons-center" v-if="!loading && !result">
                <v-btn color="primary" @click="submitDelete(modals.file.id, modals.file.url)">Delete</v-btn>
                <v-btn @click="$emit('closingModal', true)">Cancel</v-btn>
              </v-col>

              <v-col v-if="loading"
                class="text-subtitle-1 text-center"
                cols="12"
              >
                Deleting content...
              </v-col>
              <v-col cols="6" v-if="loading">
                <v-progress-linear
                  color="primary accent-4"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </v-container>

          <v-container v-if="!deletingFile">
            <div class="modal-picture-details" :class="{'static-detail': textStatus }" v-if="modals.file">
              <div class="container">
                <div class="row justify-content-center" v-show="!fbConverting">
                  <div class="col-lg-6">
                    <div class="click-region">
                      <div class="flag-container" v-if="parseInt(modals.file.categories, 10) === 1">
                        <div class="flag">Draft</div>
                      </div>
                      <div v-if="modals.file.view_type && modals.file.related_files.length > 1">
                        <img v-if="isImage(modals.file.related_files[0])" :src="`${modals.file.related_files[0]}`" @error="getSignatureUrl()" :class="`rotate_images__position_${modals.file.rotate_position[0]}`"  />
                        <video width="100%" height="100%" controls v-else>
                          <source :src="modals.file.related_files[0]" type="video/mp4">
                          Your browser does not support the video tag.
                        </video>
                      </div>
                      <div v-else>
                        <!-- cycle -->
                        <v-carousel v-if="modals.file.related_files.length > 1" v-model="currentIndex">
                          <v-carousel-item
                            v-for="(item,i) in modals.file.related_files"
                            :key="i"
                          >
                            <img v-if="isImage(item)" :src="item" @click="$emit('openZoom', modals)" @error="getSignatureUrl()" :class="`rotate_images__position_${modals.file.rotate_position[i]}`" />
                            <video width="100%" height="100%" controls v-else>
                              <source :src="item" type="video/mp4">
                              Your browser does not support the video tag.
                            </video>
                          </v-carousel-item>
                        </v-carousel>
                        <div v-else>
                          <img v-if="isImage(modals.file.related_files[0])" :src="`${modals.file.related_files[0]}`" @click="$emit('openZoom', modals)"  :class="`rotate_images__position_${modals.file.rotate_position[0]}`" />
                          <video width="100%" height="100%" controls v-else>
                            <source :src="modals.file.related_files[0]" type="video/mp4">
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      </div>
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="primary"
                        @click="$emit('openZoom', modals)"
                      >
                        <v-icon
                          right
                          dark
                          class="mx-auto">
                          {{ modals.file.view_type? mdiBookOpenVariant : mdiMagnifyScan }}
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>
                  <div v-if="actions" class="wrapper-icons">
                    <v-icon @click="deleteFile(true)" v-if="!deletingFile"
                      :class="{'alternate-icon-small': !mdiTrashCan}"
                      class="mx-auto icon-trash"
                    >
                      {{ mdiTrashCan }}
                    </v-icon>
                    <v-icon @click="$emit('openEdit', modals)" v-if="(!deletingFile && !$vuetify.breakpoint.mdAndDown)"
                      :class="{'alternate-icon-small': !mdiSquareEditOutline}"
                      class="mx-auto icon-edit"
                    >
                      {{ mdiSquareEditOutline }}
                    </v-icon>
                  </div>
                  <div class="col-lg-6 wrapper-text clearfix" v-if="textStatus">
                    <div class="inner-text" v-html="modals.file.alternative_text"></div>
                  </div>
                </div>
              </div>
            </div>
          </v-container>
          <v-chip v-if="actions"
            color="primary"
            class="ml-0 mr-2 black--text"
            label
            small
          >
            {{ modals.file.public ? 'Public' : 'Private' }}
          </v-chip>
          <a href="javascript:;" @click="showText()" class="lnk-read-more" v-if="$vuetify.breakpoint.mdAndDown">Read more</a>
        </v-card-text>
        <v-divider class="detail-divider"></v-divider>
        <v-card-actions>
          <template v-if="actions">
            <v-btn color="primary" class="share-memory" @click="$store.commit('shareMemoryRequest', true);" v-if="(!textStatus && $vuetify.breakpoint.mdAndDown) || !$vuetify.breakpoint.mdAndDown">
              Share memory request
            </v-btn>
            <v-spacer></v-spacer>
            <div class="action-icons" v-if="(!textStatus && $vuetify.breakpoint.mdAndDown) || !$vuetify.breakpoint.mdAndDown">
              <v-icon
                :class="{'alternate-icon-small': !mdiHeartOutline }"
                class="mx-auto icon-heart"
              >
                {{ mdiHeartOutline }}
              </v-icon>
              <v-icon
                :class="{'alternate-icon-small': !mdiCommentTextMultipleOutline }"
                class="mx-auto icon-comments"
              >
                {{ mdiCommentTextMultipleOutline }}
              </v-icon>
              <v-icon
                :class="{'alternate-icon-small': !mdiShareVariantOutline }"
                class="mx-auto icon-share"
              >
                {{ mdiShareVariantOutline }}
              </v-icon>
            </div>
            <v-spacer></v-spacer>
            <v-btn
              class="close-btn"
              v-if="!loading"
              @click="$emit('closingModal', result);textStatus = false;"
              :class="{'text-opened': (textStatus && $vuetify.breakpoint.mdAndDown)}"
            >
              Close
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ModalShareRequest v-if="$store.state.shareMemoryRequest" :modals="modals" />
  </v-row>
</template>

<script>
  import axios from "axios";
  import { mdiMagnifyScan, mdiBookOpenVariant, mdiCropRotate, mdiSquareEditOutline, mdiTrashCan, mdiHeartOutline, mdiCommentTextMultipleOutline, mdiShareVariantOutline } from '@mdi/js';
  import ModalShareRequest from '@/components/modals/ModalShareRequest.vue';

  export default {
    props: ['modals', 'actions', 'categoriesName', 'categoriesId'],
    data () {
      return {
        mdiMagnifyScan,
        mdiCropRotate,
        mdiBookOpenVariant,
        mdiSquareEditOutline,
        mdiTrashCan,
        mdiHeartOutline,
        mdiCommentTextMultipleOutline,
        mdiShareVariantOutline,
        textStatus: false,
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June',	'July',	'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
        deletingFile: false,
        dialog: true,
        fbConverting: false,
        endpoint: process.env.VUE_APP_ROOT_API,
        loading: true,
        result: false,
        currentIndex: 0,
        user : {
          name: ''
        }
      }
    },
    components: {
      ModalShareRequest
    },
    mounted() {
      this.loading = false;
      this.user.name = localStorage.getItem('user_name');

      if(!this.$vuetify.breakpoint.mdAndDown) {
        this.textStatus = true;
      }
    },
    methods: {
      isImage(filePath) {
        return filePath && filePath.indexOf('mp4') < 0;
      },
      showText() {
        this.textStatus = true;
      },
      getSignatureUrl() {
        // console.log('error image');
        // this.$store.commit('getFiles');
        location.reload();
      },
      getCategoryName(id) {
        return this.categoriesName[this.categoriesId.indexOf(parseInt(id, 10))];
      },
      deleteFile() {
        this.deletingFile = true;
      },
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${this.months[parseInt(month, 10) - 1]} ${day} ${year}`
      },
      submitDelete(id, url) {
        const self = this;
        self.loading = true;
        axios.post(`${self.endpoint}/deleteFileInfos`, {
          file_id: id,
          filename: url,
        }, {
        headers : {
          'Authorization': this.$store.state.jwtToken
        }
        }).then(responseDB => {
          self.loading = false;
          self.result = true;
        });
      },
    },
  }
</script>

<style scoped lang="scss">
.container {
  padding-top: 0;
}

img {
  height: auto;
  border-radius: 12px;
  max-height: 50vh;
  width: 100vw;
  margin: 0 auto;
  display: block;
  max-width: 100%;
}

.modal-picture-details {
  max-width: 900px !important;
  img {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.2);
  }

  .spinner {
    margin: 20px 0 0 -25px;
  }
}
.modal-picture-details .row {
  position: relative;
}

.modal-picture-details .click-region {
  cursor: pointer;
  height: auto;
  position: relative;
  margin-bottom: 20px;

  button {
    display: block;
    background: none;
    position: absolute;
    // bottom: -48px !important;
    // left: 48%;
    // transform: translateX(-50%);
    z-index: 20;
    right: 0px;
    bottom: 5px;
  }
}

.wrapper-text {
  max-height: 485px;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
  padding-top: 0;
  margin-top: 25px;
}

.wrapper-buttons-center {
  text-align: center;
  button {
    margin: 0 10px;
  }
}

.rotate-icon {
  position: absolute;
  bottom: -48px !important;
  left: 42% !important;
  transform: translateX(-50%);
  width: 40px;
  height: 40px;
}

.inner-text {
  margin-top: 20px;
}

.v-card__actions {
  .v-icon {
    margin: 0 8px !important;
    cursor: pointer;
  }
}

.close-btn,
.black--text,
.share-memory,
.wrapper-icons,
.avatar-infos {
  position: absolute;
  right: 20px;
  z-index: 1;
  @media(max-width: 940px) {
    right: 2%;
  }
}

.wrapper-icons {
  right: 10px;
  .icon-trash {
    float: right;
    margin-left: 7px !important;
  }
}

.close-btn, .wrapper-avatars {
  right: 45px;
  @media(max-width: 940px) {
    right: 2%;
  }
}
.share-memory,
.avatar-infos {
  left: 45px;
  @media(max-width: 940px) {
    left: 2%;
  }
}

.avatar-infos,
.share-memory {
  right: auto;
}

.black--text,
.lnk-read-more {
  right: 37px;
  bottom: 80px;
  @media(max-width: 940px) {
    right: 20px;
  }
}

.lnk-read-more {
  position: absolute;
  right: auto;
}

.wrapper-avatars {
  position: absolute;
  .v-avatar {
    border: 2px solid #fff;
  }
  >div:not(:first-child) {
    margin-left: -1rem;
  }
  > span {
    position: absolute;
    right: 129px;
    top: 7px;
    margin: 5px auto 0;
    font-size: 12px;
    display: block;
    width: 100%;
  }
}

.memory-bit-banner {
  padding: 20px;
  > .v-avatar {
    position: absolute;
  }
}

.text-h5 {
  margin-left: 45px !important;
  margin-bottom: 20px;
}

.txt-date {
  font-size: 18px;
}
@media(max-width: 940px) {
  .detail-divider {
    opacity: 0;
  }

  .text-opened {
    bottom: 20px;
  }
}
</style>
