<template>
  <v-row justify="center" class="share-memory-request">
    <v-dialog
      v-model="dialog2"
      max-width="500px"
      persistent
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <v-card>
        <v-card-title>
          Share Memory Request
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>

            <v-row
            align-content="center"
            justify="center"
          >
            <v-col
              cols="12">
              <wysiwyg v-model="description" class="editor-share-request" /> <!-- :error="descriptionCheck"  -->
            </v-col>
          </v-row>
          <v-row
            align-content="center"
            justify="center"
          >
            <v-col
              cols="12">
              <v-select
                v-model="user"
                outlined
                dense
                :items="users"
                label="Select the User"
                item-value="text"
              ></v-select>
            </v-col>
          </v-row>
          <v-row
            align-content="center"
            justify="center"
          >
            <v-col
              class="text-subtitle-1 text-center"
              cols="12">
              <v-text-field
                v-model="email"
                outlined
                dense
                label="Send the Request by E-mail"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row
            align-content="center"
            justify="center"
          >
            <v-col
              class="text-subtitle-1 text-center"
              cols="9">
              <v-text-field
                outlined
                dense
                label="Share by Link"
                readonly
                v-model="shareLink"
                disabled
              ></v-text-field>
            </v-col>
            <v-col
              class="text-subtitle-1 text-center"
              cols="3">
              <v-btn @click="copyClipboard()">Copy</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn @click="$store.commit('shareMemoryRequest', false);">
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              @click="saveMsg()"
          >
              Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  props: ['modals'],
  data () {
    return {
      dialog2: true,
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June',	'July',	'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
      endpoint: process.env.VUE_APP_ROOT_API,
      users: [],
      shared_user_id: [],
      user: null,
      shareLink: 'https://selfbiography.com/ct?code=HS7FG3C',
      description: '',
      owner: null,
      string: null,
      email: null
    }
  },
  mounted() {
    this.getUsers();
    this.string = Math.random().toString(36).substring(6).toUpperCase();
    this.shareLink = `https://beta.selfbiography.com/ct?code=${this.string}`;
  },
  watch: {
    user(value) {
      this.fillUser(value);
      // console.log(this.shared_user_id[this.users.indexOf(this.user)]);
    }
  },
  methods: {
    copyClipboard() {
      // var copyText = document.getElementById("myInput");

      // /* Select the text field */
      // copyText.select();
      // copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      navigator.clipboard.writeText(this.description);
      this.saveMsg();
    },
    fillUser(value) {
      this.description = `<strong>Hello, ${value}</strong><br /><br />

                        <p>I would like to share this memory with you because you're a part of it.<br />
                        Please write your thoughts and contribuit to this memory moment.</p>

                        <p>This is important to me and i'll really apreciate your comments.<br />
                        Please feel free to adopt this memory into your biography.</p>

                        <p>Thank you so much.</p>

                        <p>${this.owner}</p>
                      `;

    },
    getUsers() {
      axios.get(`${this.endpoint}/getUsers`, {
          headers : {
            'Authorization': this.$store.state.jwtToken
          }
        }).then(response => {
        // response.data.content.forEach(value => {
        //   // if(value.id !== parseInt(localStorage.getItem('user_id'), 10)) {
        //   //   this.users.push(value.name);
        //   //   this.shared_user_id.push(value.id);
        //   // } else {
        //   //   this.owner = value.name;
        //   // }
        // });
        this.fillUser('');
      });
    },
    saveMsg() {
      axios.post(`${this.endpoint}/saveMsgMemoryRequest`, {
        description: this.description,
        created_by_id: parseInt(localStorage.getItem('user_id'), 10),
        shared_user_id: this.user ? this.shared_user_id[this.users.indexOf(this.user)] : null,
        content_id: this.modals.file.id,
        shared_url: this.shareLink,
        email: this.email,
      }, {
        headers : {
          'Authorization': this.$store.state.jwtToken
        }
      }).then(responseDB => {
        console.log('responseDB: ', responseDB);
      });
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${this.months[parseInt(month, 10) - 1]} ${day} ${year}`
    },
  },
}
</script>

<style lang="scss">
// .share-memory-request {
  .v-text-field__details {
    display: none !important;
  }
// }
</style>
