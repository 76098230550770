<template>
  <div>
    <v-row>
      <v-col
        :cols="$vuetify.breakpoint.mdAndDown ? 8 : 5">
        <v-text-field
          v-model="maps.searchAddressInput"
          label="Enter a location"
          persistent-hint
          outlined
          dense
          full-width
          @keyup="search"
          v-on:keyup.enter="searchLocation()"
        ></v-text-field>
        <ul role="menu" class="places-dropdown" v-if="maps.places.length > 0">
          <li><strong>Suggestions</strong></li>
          <li role="menuitem" class="place" v-for="place in maps.places" :key="place.PlaceId" @click="selectPlace(place)">
            {{ place.Text }}
          </li>
        </ul>
      </v-col>
      <v-col
        :cols="$vuetify.breakpoint.mdAndDown ? 2 : 2"
      >
        <v-btn
          color="primary"
          @click="searchLocation()"
        >
          Search
        </v-btn>
        <v-tooltip top color="#000000" max-width="200px" v-if="!$vuetify.breakpoint.mdAndDown">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :class="{'alternate-icon-small': !mdiInformationOutline}"
              class="mx-auto ml-2"
              title="What's a tag?"
              v-bind="attrs"
              v-on="on"
            >
              {{ mdiInformationOutline }}
            </v-icon>
          </template>
          <span>Activate your <strong>current location</strong> or <strong>Search by Address</strong> to link this content to a location or click on the map to set manually.<br />
          You can drag the marker to change the location.</span>
        </v-tooltip>
      </v-col>
      <v-col
        :cols="$vuetify.breakpoint.mdAndDown ? 12 : 5">
          <v-switch
          v-model="switchLocation"
          :label="`My location`"
        ></v-switch>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12">
        <div class="mapPane">
          <v-progress-circular
            :size="70"
            :width="7"
            color="primary"
            indeterminate
            class="progress-map" v-if="loading"
          ></v-progress-circular>
          <div id="map"></div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import maplibregl from 'maplibre-gl';
  import { Auth, Signer } from 'aws-amplify';
  import awsconfig from '../aws-exports';
  import Location from 'aws-sdk/clients/location';
  import { mdiInformationOutline } from '@mdi/js';

  let map;

  export default {
    name: 'MapPane',
    props: ['coords'],
    data() {
      return {
        mdiInformationOutline,
        credentials: null,
        client: null,
        params: null,
        marker: null,
        position: {},
        switchLocation: false,
        loading: true,
        maps: {
          center: { lat : 0, lng : 0},
          markers: false,
          zoom: 10,
          searchAddressInput: null,
          places: []
        },
      };
    },
    watch: {
      switchLocation(val) {
        if (val) {
          this.geolocation();
        }
      },
      // coords() {
      //   this.position = this.coords;
      //   this.addMarker();
      // },
    },
    beforeMount() {
      this.position = this.coords;
    },
    mounted: async function() {
      this.credentials = await Auth.currentCredentials();
      this.mapCreate();
      // this.addPlaceSearch();
    },
    methods: {
      geolocation() {
        const self = this;
        self.loading = true;
        navigator.geolocation.getCurrentPosition((position) => {
          self.position = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          self.loading = false;
          self.addMarker();
        });
      },
      search: async function() {
        if(this.maps.searchAddressInput.length)
          this.suggestSearch(this.maps.searchAddressInput);
      },
      mapCreate: function() {
        map = new maplibregl.Map({
          container: 'map',
          style: 'SelfBiography-staging',
          center: [this.position.lng, this.position.lat],
          zoom: 4,
          transformRequest: this.transformRequest,
        });
        const self = this;
        map.on('load', function () {
          self.loading = false;
        });

        map.addControl(new maplibregl.NavigationControl());
        map.addControl(new maplibregl.FullscreenControl());
        this.addMarker();
      },
      transformRequest: function(url, resourceType) {
        if (resourceType === 'Style' && !url.includes('://')) {
          url = `https://maps.geo.${awsconfig.aws_project_region}.amazonaws.com/maps/v0/maps/${url}/style-descriptor`;
        }
        if (url.includes('amazonaws.com')) {
          return {
            url: Signer.signUrl(url, {
              access_key: this.credentials.accessKeyId,
              secret_key: this.credentials.secretAccessKey,
              session_token: this.credentials.sessionToken,
            }),
          };
        }
        return { url };
      },
      addMarker() {
        if(this.marker) this.marker.remove();
        this.marker = new maplibregl.Marker({
          color: '#9155fd',
          draggable: true
        })
        .setLngLat([this.position.lng, this.position.lat])
        // .setPopup(popup);
        this.marker.addTo(map);
        this.marker.on('dragstart', this.onDragStart);
        this.marker.on('dragend', this.onDragEnd);
        map.flyTo({
          center: [this.position.lng, this.position.lat]
        });
        this.$emit('updateCoordinates', this.position);
        this.maps.places = [];
      },
      removeMarker() {
        this.marker.remove();
      },
      onDragStart() {
        this.maps.searchAddressInput = '';
        this.switchLocation = false;
        this.maps.places = [];
      },
      onDragEnd() {
        const lngLat = this.marker.getLngLat();
        this.position = {
          lat: lngLat.lat,
          lng: lngLat.lng
        }
        this.$emit('updateCoordinates', this.position);
      },
      suggestSearch: async function(term) {
        const location = new Location({
          credentials: this.credentials,
          region: awsconfig.aws_project_region,
        });
        const results = await location.searchPlaceIndexForSuggestions({
          IndexName: 'SelfBiographySearch-staging', // required
          Text: term // required
        }).promise();
        this.maps.places = results.Results;
      },
      searchLocation() {
        this.client = new Location({
          credentials: this.credentials,
          region: awsconfig.aws_project_region,
        });
        this.params = {
          IndexName: 'SelfBiographySearch-staging',
          MaxResults: 1,
          Text: this.maps.searchAddressInput,
        };

        this.client.searchPlaceIndexForText(this.params, (err, data) => {
          const label = data.Results[0].Place.Label;
          const lng = data.Results[0].Place.Geometry.Point[0];
          const lat = data.Results[0].Place.Geometry.Point[1];
          this.switchLocation = false;
          this.position = {
            lat: lat,
            lng: lng
          }
          this.addMarker();
          this.$emit('updateCoordinates', this.position);
        });
      },
      selectPlace(place) {
        this.maps.searchAddressInput = place.Text;
        this.maps.places = [];
        this.switchLocation = false;
        this.searchLocation();
      },
    },
  };
</script>

<style scoped lang="scss">
  #map {
    z-index: 0;
    height: 60vh;
    max-height: 500px;
  }
  .v-input--switch {
    float: right;
  }
  .v-messages {
    display: none;
  }

  .places-dropdown {
    position: absolute;
    top: 55px;
    z-index: 1;
    background: #fff;
    width: 100%;
    max-width: 428px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 3px rgb(0 0 0 / 40%);
    list-style: none;
    max-height: 354px;
    overflow-y: auto;

    li {
      padding: 8px;
      border-bottom: 1px solid #ccc;
      cursor: pointer;
      &:last-child {
        border: 0;
      }
    }
  }

  .progress-map {
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
</style>
